// opt-api.me-interactive.net
// lk-api.odejda-opt.su
// opt-api2.me-interactive.net
// opt-apidev.me-interactive.net

const {
  REACT_APP_API_DOMAIN = 'lk-api.odejda-opt.su',
  REACT_APP_GOOGLE_API_KEY = 'AIzaSyB_rjiR94heML-tlGsexMmxwtpVQ7WlcxI',
  PHONE_MASK = '+9 999 999 99 99',
} = process.env;
const YANDEX_MAPS_API_KEY = 'a5c6b9b6-07a4-45b8-92c4-f280504e96ed';
const protocol = 'https:';
const API_DOMAIN = `${protocol}//${REACT_APP_API_DOMAIN}`;

const CHAT_DOMAIN = 'https://chat-dev.me-interactive.net/api/v4';
const CHAT_SOCKET = 'wss://chat-dev.me-interactive.net/api/v4/websocket';

const API_SEARCH_LOCATION_URI = `https://geocode-maps.yandex.ru/1.x/?apikey=${YANDEX_MAPS_API_KEY}&format=json`;

export {
  API_DOMAIN,
  API_SEARCH_LOCATION_URI,
  PHONE_MASK,
  CHAT_DOMAIN,
  CHAT_SOCKET,
  YANDEX_MAPS_API_KEY,
};

export const LOGIN_URL = 'api/user/login';
export const LOGIN_BY_PHONE = 'api/login/by_phone';
export const SEND_CODE = 'api/login/send_code';
export const REGISTER_URL = 'api/_p/user/register';
export const ME_URL = 'api/me';
export const REQUEST_PASSWORD_URL = 'api/auth/forgot-password';
export const getRecoveryPasswordUrl = (email: string): string =>
  `/api/_p/reset_password/send_code?email=${email}`;
export const NEW_PASSWORD_URL = '/api/_p/change_password_from_link';
export const FOR_DAYS_URL = '/api/settings/new_days';

export const CREATE_USER_URL = '/api/user/create';
export const getUserUrl = (id: number): string => `/api/user/${id}`;
export const SEND_INVITE_URL = '/api/user_activate/send';
export const delUserUrl = (id: number): string => `/api/user/${id}`;
export const uploadUserAvatarUrl = (id: string): string => `/api/user/${id}/upload_photos`;

export const GET_COMPANIES_URL = '/api/companies';
export const CREATE_COMPANY_URL = '/api/company';
export const SEARCH_COMPANY_URL = '/api/companies/search';
export const getCompanyUrl = (id: number): string => `/api/company/${id}`;
export const editCompanyUrl = (id: number): string => `/api/company/${id}`;
export const delCompanyUrl = (id: number): string => `/api/company/${id}`;

export const uploadPhotoUrl = (modificationId: number): string =>
  `/api/modification/${modificationId}/upload_photos`;
export const delPhotoUrl = (id: number): string => `/api/photo/${id}`;
export const setMainPhotoUrl = (id: number): string => `/api/photo/${id}/set_main`;

export const CREATE_CATEGORY_URL = '/api/shop/category';
export const getCategoryUrl = (id: number | undefined) => `/api/shop/category/${id}`;
export const FULL_CATEGORIES_URL = '/api/shop/categories/full';
export const ROOT_CATEGORIES_URL = '/api/shop/categories/roots';
export const getCategoriesWithChildrenUrl = (id: number) =>
  `/api/shop/category/${id}/children`;
export const getCategoriesWithParentUrl = (id: number) => `/api/shop/category/${id}/parents`;
export const getCategoriesPositionsUrl = (positions: string) =>
  `/api/shop/categories/set_positions?positions=${positions}`;

export const PRODUCT_URL = '/api/shop/product';
export const PRODUCTS_URL = '/api/shop/products';
export const getProductByIdUrl = (id: number) => `/api/shop/product/${id}`;
export const getProductsByCategory = (id: number) => `/api/shop/category/${id}/products`;
export const delPhotoFromProduct = (id: number) => `/api/shop/product_photo/${id}`;
export const setProductMainPhotoUrl = (id: number): string =>
  `/api/shop/product_photo/${id}/set_main`;
export const getReviewsUrl = (productId: number, perPage: number, page: number) =>
  `/api/shop/product/${productId}/reviews?page=${page}&per_page=${perPage}`;
export const createReviewUrl = (productId: number) => `/api/shop/product/${productId}/review`;
export const delReviewUrl = (reviewId: number) => `/api/shop/review/${reviewId}`;

export const PRODUCT_TYPES_URL = '/api/shop/product_types';
export const getProductTypeByIdUrl = (id: number) => `/api/shop/product_type/${id}`;
export const PRODUCT_TYPE_URL = '/api/shop/product_type';
export const delProdyctTypeByIdUrl = (id: number) => `/api/shop/product_type/${id}`;

export const addParameterForType = (id: number) => `/api/shop/product_type/${id}/parameter`;
export const editParameterForType = (id: number) => `/api/shop/product_type_parameter/${id}`;
export const getParametersForType = (id: number) => `/api/shop/product_type/${id}/parameters`;

export const getOrdersByStatus = (statuses: string) => `/api/shop/orders?statuses=${statuses}`;
export const getOrderurl = (id: number) => `/api/shop/order/${id}`;

export const STORES_URL = '/api/shop/stores';
export const CREATE_STORE_URL = '/api/shop/store';
export const getStoreByIdUrl = (id: number) => `api/shop/store/${id}`;

export const getStores = (companyId: number) =>
  `${STORES_URL}?companyId=${companyId}&page=${1}$per_page=${999}`;
export const getCartUrl = (hash: string) => `/api/shop/cart/${hash}`;
export const deleteCartUrl = (hash: string) => `/api/shop/cart/${hash}`;
export const clearCartUrl = (hash: string) => `/api/shop/cart/${hash}/clear`;
export const createCartUrl = () => `/api/shop/cart`;
export const addProuctToCartUrl = (hash: string, product_id: number, count: number) =>
  `/api/shop/cart/add_product?hash=${hash}&product_id=${product_id}&count=${count}`;
export const setProductCountUrl = (hash: string, product_id: number, count: number) =>
  `/api/shop/cart/${hash}/products_count?product_id=${product_id}&count=${count}`;
export const checkoutCartUrl = (hash: string) => `/api/shop/cart/${hash}/checkout`;
export const sendSmsUrl = (country_name: string = 'Russia', country_code: string = '7') =>
  `/api/users/code_register_sms?country_name=${country_name}&country_code=${country_code}`;
export const GET_COUNTRY_ID = '/api/phone_codes';

export const PUBLICATIONS_URL = '/api/publications';
export const PUBLICATION_URL = '/api/publication';

export const setWorkingTimeUrl = (id: number) => `/api/shop/store/${id}/working_time`;

export const createPublicationUrl = () => `${PUBLICATION_URL}`;
export const editPublicationUrl = (id: number) => `${PUBLICATION_URL}/${id}`;
export const getPublicationUrl = (id: number) => `${PUBLICATION_URL}/${id}`;
export const deletePublicationUrl = (id: number) => `${PUBLICATION_URL}/${id}`;
export const getAllPublicationsUrl = (data: { page: number; perPage: number }) =>
  `${PUBLICATIONS_URL}/list?page=${data.page}&per_page=${data.perPage}`;
export const getMyPublicationsUrl = (data: { page: number; perPage: number }) =>
  `${PUBLICATIONS_URL}/my?page=${data.page}&per_page=${data.perPage}`;
export const createPublicationLogoUrl = (id: number) => `${PUBLICATION_URL}/${id}/upload_logo`;

export const getMessagesUrl = (order_id: number, page: number, perPage: number) =>
  `api/messages/list?order_id=${order_id}&page=${page}&per_page=${perPage}`;
export const getCreateMessageUrl = () => 'api/message';

export const deleteParameterForType = (id: number) => `/api/shop/parameter/${id}`;

export const GET_PROMOCODES_URL = '/api/promo_codes';
export const GET_PROMOCODES_STAT_URL = 'api/promo_code_stat_all';
export const getPromocodeStatUrl = (id: number) => `api/promo_code_stat?id=${id}`;
export const CREATE_PROMOCODE_URL = '/api/promo_code/create';
export const editPromocodeUrl = (id: number): string => `/api/promo_code/${id}`;
export const getPromocodeUrl = (id: number): string => `/api/promo_code/${id}`;
export const activatePromocodeUrl = (code: string): string => `/api/code_activation/${code}`;

export const GET_TRANSACTIONS_URL = '/api/transactions';

export const editUserTariff = (userId: number, tariffId: number, days?: number) => {
  if (days) {
    return `/api/user_tariff?user=${userId}&tariff=${tariffId}&days=${days}`;
  }
  return `/api/user_tariff?user=${userId}&tariff=${tariffId}`;
};
