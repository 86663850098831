import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { getResponseMessage } from '../../../../utils/utils';
import { searchProducts } from '../../../../crud/product.crud';

export const useSearchList = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingSearch, setLoading] = useState(false);
  const [productsSearch, setProducts] = useState<{ id: number; name: string }[]>([]);
  const fetchSearch = useCallback(async (text: string) => {
    setLoading(true);
    searchProducts(text)
      .then(res => {
        setProducts(res.data.data);
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const clearSearch = () => {
    setProducts([]);
  };

  return { fetchSearch, loadingSearch, productsSearch, clearSearch };
};
