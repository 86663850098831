import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getReviews } from '../../../../crud/product.crud';
import { getResponseMessage } from '../../../../utils/utils';
import { actions as productActions } from '../../../../store/ducks/product.duck';
import { useFormatMessage } from '../../../../hooks';

export const useGetReviews: any = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const fm = useFormatMessage();

  const fetchReviews = useCallback(
    async (productId: number, perPage: number, page: number) => {
      setLoading(true);
      getReviews(productId, perPage, page)
        .then(res => {
          dispatch(
            productActions.setProductReviews({
              rating: res.data.product_rating,
              data: res.data.data,
              total: res.data.total,
              me_add: res.data.me_add,
            })
          );
          setSuccess(true);
        })
        .catch(e => {
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
          setErr(null);
          setSuccess(false);
        });
    },
    [dispatch]
  );

  return [fetchReviews, loading, success, err];
};
