import { useSelector } from "react-redux";
import { IOrderType } from '../../../interfaces/order';
import { useDefineUserRole } from "../../../hooks";

export enum ListType {
  ABANDONED = 'abandoned',
  FULL = 'full',
}

export const orderTypeList: string[] = [
  'new',
  'formation',
  'ready_to_delivery',
  'in_processing',
  // 'payed',
  'canceled',
  'completed',
];

export const orderTypeListBuyer: string[] = [
  'new',
  'formation',
  'ready_to_delivery',
  'in_processing',
  'canceled',
  'completed',
  'archive'
];

export const orderStatusList: string[] = ['paid', 'not_paid'];

export const getOrderTypeWithTranslates = (
  translates: { [type: string]: string },
  isFull: boolean,
  isBuyer: boolean
): IOrderType[] => {
  const orderTypes: IOrderType[] = (isBuyer ? orderTypeListBuyer : orderTypeList).map(item => ({
    type: item,
    translate: translates[item],
  }));
  
  if (isFull) {
    orderTypes.unshift({ type: 'cart', translate: translates.cart });
  }
  return orderTypes;
};

export const getStatusTypeWithTranslates = (
  translates: { [type: string]: string },
  isFull: boolean
): IOrderType[] => {
  const orderTypes: IOrderType[] = orderStatusList.map(item => ({
    type: item,
    translate: translates[item],
  }));
  return orderTypes;
};

export const useOrderTypes = (translates: { [type: string]: string }, isFull: boolean): IOrderType[] => {
  const { me } = useSelector(({ profile }: any) => profile);
  const isBuyer = useDefineUserRole(me, 'ROLE_BUYER');
  
  return getOrderTypeWithTranslates(translates, isFull, !!isBuyer);
};
