import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    dialog: {
      position: 'absolute',
      left: 0,
      top: 66,
      margin: 0,
      boxShadow: 'none',
    },
    root: {
      color: theme.palette.text.secondary,
      margin: 0,
      paddingLeft: 20,
      listStyle: 'none',
      '&:focus > $content, &$selected > $content': {
        backgroundColor: `var(--tree-view-bg-color)`,
        color: 'var(--tree-view-color)',
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
      '&:hover > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
    },
    cardCategory: {
      //   marginBottom: theme.spacing(3),
    },
    cardButtons: {
      justifyContent: 'flex-end',
      padding: 16,
    },
    button: {
      '&:active': {
        opacity: 0.2,
      },
    },
    buttonReset: {
      marginRight: theme.spacing(2),
    },
    content: {
      color: theme.palette.text.primary,
        fontWeight: 500,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 0,
    },
    expanded: {},
    selected: {
      backgroundColor: `var(--tree-view-bg-color)`,
    },
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: theme.spacing(0.7),
      paddingTop: theme.spacing(0.7),
      width: '100%',
      padding: 10,
      cursor: 'pointer',
      position: 'relative',
      transition: '.25s ease-out',
    },
    labelContainerChildren: {
      maxHeight: '100%',
      overflow: 'hidden',
    },
    labelChildren: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: theme.spacing(0.7),
      paddingTop: theme.spacing(0.7),
      width: '100%',
      padding: 10,
      cursor: 'pointer',
      paddingLeft: 30,
      transition: '.25s ease-out',
    },
    labelIcon: {},
    labelText: {
      fontWeight: 500,
      flexGrow: 1,
      fontSize: 14,
      color: '#002626',
    },
    activeItem: {
      backgroundColor: 'rgba(54, 159, 247,0.5)',
      color: theme.palette.primary.main,
    },
    hide: {
      height: 0,
    },
    cardContent: {
      padding: '20px 30px',
      maxWidth: 340,
      maxHeight: 400,
      overflow: 'auto',
    },
  })
);
