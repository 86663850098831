import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { connect, ConnectedProps, useSelector, shallowEqual } from 'react-redux';
import { Card, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { Button } from '../../../components/ui/Buttons';

import { a11yProps, TabPanel } from '../../../components/other/Tab/TabPanel';
import { IAppState } from '../../../store/rootDuck';
import { setLayoutSubheader } from '../../../utils/layout';
import { actions as companiesActions } from '../../../store/ducks/companies.duck';
import Preloader from '../../../components/other/Preloader/Preloader';
import { API_DOMAIN } from '../../../constants';
import RichEditor from '../../../components/formComponents/RichEditor';
import { toAbsoluteUrl } from '../../../../_metronic';
import ImageDialog from '../../../components/other/ImageDialog/imageDialog';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { useStyles } from './hooks/useStyles';
import { useFormatMessage, useShowErrors, useDefineUserRole } from '../../../hooks';
import Rating from '../products/components/Rating';
import StarIcon from '../products/components/StarIcon';

const CompanyView: React.FC<RouteComponentProps<{ id: string }> & TPropsFromRedux> = ({
  match: {
    params: { id },
  },
  company,
  companyError,
  loading,
  fetch,
  clearDelCompany,

  me,
  meError,
  loadingMe,
  fetchMe,
  clearMe,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const fm = useFormatMessage();
  const reviewsProduct = useSelector(
    ({ products: { reviewsProduct } }: IAppState) => reviewsProduct,
    shallowEqual
  );

  const [valueTabs, setValueTabs] = useState(0);
  const [isImageOpen, setImageOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [visibleModalReviews, setVisibleModalReviews] = useState(false);
  const isAdmin = useDefineUserRole(me, 'ROLE_ADMIN');
  const isBuyer = useDefineUserRole(me, 'ROLE_BUYER');

  const titles = [
    'Главная база',
    'Название второй точки',
    'Название третьей точки',
    'Название четвертой точки',
    'Название пятой точки',
    'Название шестой точки',
    'Название седьмой точки',
  ];

  setLayoutSubheader({ title: fm('COMPANY.TITLE.VIEW') });

  useEffect(() => {
    fetchMe();
    return () => {
      clearMe();
    };
  }, []);

  useEffect(() => {
    fetch(Number(id));
    return () => {
      clearDelCompany();
    };
  }, [id]);

  const descriptionHtml = useMemo(() => {
    if (company?.description) {
      return EditorState.createWithContent(stateFromHTML(company.description));
    }
    return EditorState.createEmpty();
  }, [company]);

  const goToEditCompany = useCallback(() => {
    company && history.push(`/companies/edit/${company.id}`);
  }, [history, company]);

  const handleImage = useCallback(
    url => {
      if (isImageOpen) {
        setImageUrl(null);
        setImageOpen(false);
      } else {
        setImageUrl(url);
        setImageOpen(true);
      }
    },
    [isImageOpen, imageUrl]
  );

  useShowErrors([meError, companyError]);

  if (loading || !company || loadingMe) return <Preloader />;

  return (
    <>
      {imageUrl && <ImageDialog open={isImageOpen} handleClose={handleImage} url={imageUrl} />}
      <Paper className={classes.containerPaper}>
        <div className={classes.container}>
          {/* <div className={classes.header}>
            <Typography variant='h5'>{`${company.name}`}</Typography>

            {me?.is_admin && (
              <Button color='primary' variant='contained' onClick={() => goToEditCompany()}>
                {fm('COMPANY.TABLE.BUTTON.EDIT')}
              </Button>
            )}
          </div> */}
          <div className={classes.card}>
            <div className={classes.leftCol}>
              <Card
                className={classes.imgContainer}
                elevation={0}
                style={{ height: 'auto', maxHeight: 400, alignItems: 'start' }}
              >
                <img
                  onClick={() => handleImage(`${API_DOMAIN}/${company.logo}`)}
                  src={
                    company.logo
                      ? `${API_DOMAIN}/${company.logo.path}`
                      : toAbsoluteUrl('/images/placeholder.png')
                  }
                  className={classes.img}
                  alt=''
                />
              </Card>

              <p className={classes.name}>
                <b>{company?.brand}</b>
              </p>
              <p className={classes.listKey}>{fm('COMPANY.NAME.VIEW')}</p>

              {(isBuyer || isAdmin) && (
                <div className={classes.feedButton}>
                  <Button
                    color='primary'
                    variant='contained'
                    className={classes.btn}
                    onClick={() => {
                      history.push(`/products/list/U${company.id}/${company.id}`);
                    }}
                  >
                    <div className={classes.btnText}>{fm('MENU.COMPANY.PRODUCTS.ALL')}</div>
                  </Button>
                </div>
              )}

              {company.rating && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginTop: 20,
                    }}
                  >
                    <StarIcon />
                    <p className={classes.listText}>{company.rating}</p>
                  </div>
                  <p className={classes.listKey}>{fm('COMPANY.RATING.VIEW')}</p>
                </>
              )}

              {company.name && (
                <>
                  <p style={{ marginTop: 20 }} className={classes.title}>
                    <b>{company?.name}</b>
                  </p>
                  <p className={classes.listKey}>{fm('COMPANY.ENTITY.VIEW')}</p>
                </>
              )}

              {company.phone_number && (
                <>
                  <p style={{ marginTop: 20 }} className={classes.num}>
                    <b>{company.phone_number}</b>
                  </p>
                  <p className={classes.listKey}>{fm('COMPANY.NUM.VIEW')}</p>
                </>
              )}

              {company.site && (
                <>
                  <p style={{ marginTop: 20 }} className={classes.num}>
                    <b>{company?.site}</b>
                  </p>
                  <p className={classes.listKey}>{fm('COMPANY.SITE.VIEW')}</p>
                </>
              )}

              {/* <p style={{marginTop: 20}} className={classes.title}>
                <b>г. Москва, ул. Нижегородская, д.32</b>
              </p>
              <p className={classes.listKey}>{fm('COMPANY.ADDRESS.VIEW')}</p> */}

              <p style={{ marginTop: 20 }} className={classes.title}>
                <b>пн - сб 09:00 - 19:00</b>
              </p>
              <p className={classes.listKey}>{fm('COMPANY.HOURS.VIEW')}</p>

              {company.description && (
                <p
                  className={classes.description}
                  style={{ marginTop: 25 }}
                  dangerouslySetInnerHTML={{ __html: company.description }}
                />
              )}

              {/* {company.stores && company.stores.length > 0 && (
                <p style={{marginTop: 25}} className={classes.titleOut}>
                <b>Торговые точки</b>
              </p>
              )}

              {company.stores && company?.stores.map((item, index) => (
                <>
                <p style={{marginTop: 20}} className={classes.subTitle}>
                  <b>{titles[index]}</b>
                </p>
                <p className={classes.outKey}>{item?.name}</p>
                <p className={classes.outNum}>{item?.phone_number}</p>
                </>
              ))} */}
            </div>

            <div className={classes.rightCol}>
              <div className={classes.companyCard} style={{ marginTop: 0 }}>
                <div className={classes.statisticsHeader}>
                  <Rating rating={company?.rating} />

                  <Typography style={{ fontSize: 17, fontWeight: 'bold' }}>
                    {company?.rating || 0} / 5
                  </Typography>
                </div>

                {reviewsProduct && (
                  <div className={classes.statisticsList}>
                    {Array(5)
                      .fill('')
                      .map((_, index) => {
                        const length =
                          reviewsProduct.reviews.filter(item => item.rating === 5 - index)
                            .length || 0;
                        const percent = (length * 100) / reviewsProduct.reviews.length || 0;

                        return (
                          <div key={index} className={classes.statisticsItem}>
                            <Typography className={classes.ratingInfoText}>
                              {5 - index} зв.
                            </Typography>
                            <div className='progress'>
                              <span style={{ width: `${percent}%` }} />
                            </div>
                            <Typography className={classes.ratingInfoText}>
                              {length}
                            </Typography>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
              {company.stores && company.stores.length > 0 && (
                <p style={{ marginTop: 25 }} className={classes.titleOut}>
                  <b>Торговые точки</b>
                </p>
              )}

              {company.stores &&
                company?.stores.map((item, index) => (
                  <>
                    <p style={{ marginTop: 20 }} className={classes.subTitle}>
                      <b>{titles[index]}</b>
                    </p>
                    <p className={classes.outKey}>{item?.name}</p>
                    <p className={classes.outNum}>{item?.phone_number}</p>
                  </>
                ))}

              {/* {(isBuyer || isAdmin) && (
              <div className={classes.feedButton}>
                <Button color='primary' className={classes.btn} onClick={() => setVisibleModalReviews(true)}>
                  <div className={classes.btnText}>{fm('REVIEW.CREATE')}</div>
                </Button>
              </div>
            )} */}
            </div>

            {/* <Tabs
            value={valueTabs}
            onChange={(_: any, newValue: number) => setValueTabs(newValue)}
            variant='scrollable'
            indicatorColor='primary'
            textColor='primary'
            aria-label='tabs'
          >
            {Boolean(company.description) && (
              <Tab label={fm('PRODUCT.TABLE.DESCRIPTION')} {...a11yProps(0)} />
            )}
          </Tabs>
          {Boolean(company.description) && (
            <TabPanel value={valueTabs} index={0}>
              <div className={classes.containerBot}>
                <RichEditor
                  disabled
                  editorState={descriptionHtml}
                  placeholder=''
                  setEditorState={() => {}}
                  className={classes.richEditor}
                  elevation={0}
                />
              </div>
            </TabPanel>
          )} */}
          </div>
        </div>
      </Paper>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    company: state.companies.company,
    companyError: state.companies.byIdError,
    loading: state.companies.byIdLoading,
    byIdSuccess: state.companies.byIdSuccess,
    isAuthorized: state.auth.user != null,

    me: state.profile.me,
    meError: state.profile.error,
    loadingMe: state.profile.loading,
  }),
  {
    fetch: companiesActions.fetchByIdRequest,
    clearDelCompany: companiesActions.clearDelCompany,

    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CompanyView);
