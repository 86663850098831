import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';

import { makeAxiosPostRequest } from '../../../utils/utils';
import { IAppState } from '../../../store/rootDuck';

export const useAxiosRegistrations = () => {
  const intl = useIntl();

  const { tokenAuth } = useSelector(
    ({ auth }: IAppState) => ({
      tokenAuth: auth.tokenAuth,
    }),
    shallowEqual
  );

  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);
  const [requestedType, setRequestedType] = useState<'email' | 'phone' | null>(null);

  const makeRequest = useCallback(
    (type: 'email' | 'phone', submitValues: any, countryData: any) => {
      if (tokenAuth) {
        const commonData = submitValues.role
          ? {
              roles: [submitValues.role],
              fio: submitValues.fio,
              locale: submitValues.locale,
              countryId: countryData.id,
              countryCode: countryData.code,
            }
          : {
              fio: submitValues.fio,
              locale: submitValues.locale,
              countryId: countryData.id,
              countryCode: countryData.code,
            };
        let url = '/api/_p/user/register';
        let data = null;

        if (type === 'email') {
          data = {
            ...commonData,
            email: submitValues.email,
          };
        }

        if (type === 'phone') {
          url = '/api/users/code_register_sms';
          // url += `?country_id=${countryData.id}`;
          // url += `&country_name=${countryData.en_name}`;
          // url += `&country_code=${countryData.code}`;
          data = {
            ...commonData,
            phoneNumbers: submitValues.phone,
          };
        }

        setRequestedType(type);
        makeAxiosPostRequest(url, data, setData, setLoading, setErr, {
          params: { cloudflare: tokenAuth },
        });
      }
    },
    [intl, tokenAuth]
  );

  useEffect(() => {
    if (data) {
      (window as any).turnstile.reset();
      (window as any).turnstile.execute();
    }
  }, [data]);

  useEffect(() => {
    if (err) {
      (window as any).turnstile.reset();
      (window as any).turnstile.execute();
    }
  }, [err]);

  return [data, loading, err, makeRequest, requestedType];
};
