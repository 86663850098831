import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { delReview } from '../../../../crud/product.crud';
import { getResponseMessage } from '../../../../utils/utils';
import { useGetReviews } from './useGetReviews';

export const useDeleteReview: any = () => {
  const [fetchReviews] = useGetReviews();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);
  const [success, setSuccess] = useState(false);

  const deleteReview = useCallback(
    async (reviewId: number, productId: number, perPage: number, page: number) => {
      setLoading(true);
      delReview(reviewId)
        .then(() => {
          fetchReviews(productId, perPage, page);
          enqueueSnackbar('Отзыв удален', { variant: 'success' });
          setSuccess(true);
        })
        .catch(e => {
          enqueueSnackbar(`Ошибка: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
          setErr(null);
          setSuccess(false);
        });
    },
    []
  );

  return [deleteReview, loading, success, err];
};
